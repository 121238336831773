@media (max-width: 645px) {
    html {
        font-size: calc(100vw * 0.024);
    }
}

body {
    width: 100%;
    background: #fff;
    font-family: 'Montserrat', sans-serif;
}

sup {
    padding-left: 0.2rem;
    font-size: 50%;
    line-height: 0;
}

.content {
    max-width: 645px;
    margin: 0 auto;
    width: 100%;
    font-size: 2.91vw;

    &__loader {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        flex-direction: column;
    }
}

.category {
    &__list {
        padding: 0.7rem 1.9rem 0;
        list-style: none;
    }

    &__page {
        padding: 0.7rem 1.9rem 0;
        list-style: none;
    }

    &__homelink:before {
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='20' viewBox='0 0 24 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 3.16471L18 8.45882V17.6471H15.6V10.5882H8.4V17.6471H6V8.45882L12 3.16471ZM12 0L0 10.5882H3.6V20H10.8V12.9412H13.2V20H20.4V10.5882H24L12 0Z' fill='%230158BB'/%3E%3C/svg%3E%0A");
        content: '';
        // width: 24px;
        // height: 25px;
        position: absolute;
        background-repeat: no-repeat;
        left: 0;
        top: 0.15rem;
        width: 1.55rem;
        height: 1.55rem;
        background-size: contain;
    }
    
    &__homelink {
        text-decoration: none;
        color: #0158BB;
        position: relative;
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 1.7rem;
        display: block;
        padding-left: 2.1rem;
        margin-bottom: 2.4rem;
    }

    &__title {
        display: flex;
        // align-items: center;
        font-weight: 700;
        font-size: 2rem;
        line-height: 2.5rem;
        padding-bottom: 0.7rem;
    }

    &__title-placeholder {
        height: 20px;
    }

    &__back-link {
        background-image: url("data:image/svg+xml,%3Csvg width='17' height='29' viewBox='0 0 17 29' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.625 25.425L6.29054 14.5L16.625 3.575L13.4797 0.25L-6.22887e-07 14.5L13.4797 28.75L16.625 25.425Z' fill='black'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-size: contain;
        width: 1.1rem;
        height: 1.9rem;
        padding-right: 2rem;
        margin-top: 0.3rem;
    }

    &__link {
        background: #fff;
        padding: 0.8rem 3rem 0.8rem 1.9rem;
        display: flex;
        align-items: center;
        margin-bottom: 0.8rem;
        color: #000;
        text-decoration: none;
        border: 1px solid #000000;
        border-radius: 4px;
        font-weight: 500;
        font-size: 1.55rem;
        line-height: 2rem;
        // min-height: 4rem;
        height: 3.8rem;

        transition: .3s all;

        position: relative;
        transition: 0.5s;
        overflow: hidden;
    }

    &__link-title {
        width: fit-content;
        height: 5rem;
        display: flex;
        align-items: center;
        pointer-events: none;
        transition: none;
    }

    &__link::after {
        opacity: 1;
        transition-delay: .2s;  
    }

    &__link:hover {
        background: #0158BB;
        color: #fff;
    }

    &__link.category__link--endpoint:hover {
        color: #fff;
    }

    &__link.category__link--endpoint:after {
        content: '';
        right: 1rem;
        top: 1rem;
        background-image: url("data:image/svg+xml,%3Csvg width='27' height='13' viewBox='0 0 27 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.03742 6.5013C3.03742 4.29255 4.83284 2.49714 7.04158 2.49714H12.2083V0.0429688H7.04158C3.47659 0.0429688 0.583252 2.9363 0.583252 6.5013C0.583252 10.0663 3.47659 12.9596 7.04158 12.9596H12.2083V10.5055H7.04158C4.83284 10.5055 3.03742 8.71005 3.03742 6.5013ZM8.33325 7.79297H18.6666V5.20964H8.33325V7.79297ZM19.9583 0.0429688H14.7916V2.49714H19.9583C22.167 2.49714 23.9624 4.29255 23.9624 6.5013C23.9624 8.71005 22.167 10.5055 19.9583 10.5055H14.7916V12.9596H19.9583C23.5233 12.9596 26.4166 10.0663 26.4166 6.5013C26.4166 2.9363 23.5233 0.0429688 19.9583 0.0429688Z' fill='%230170ED'/%3E%3C/svg%3E%0A");
        // width: 26px;
        // height: 13px;
        width: 1.65rem;
        height: 0.9rem;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
    }

    &__link.category__link--endpoint:hover:after {
        filter: brightness(5);
    }
    
    &__link.category__link--endpoint {
        border-color: rgba(1, 112, 237, 1);
        color: #0170ED;
        position: relative;
    }

    &__description {
        padding: 0 0px 1.9rem 2.9rem;
        font-weight: 600;
        font-size: 1.38rem;
        line-height: 1.68rem;
    }

    &__description--home {
        padding-left: 0;
    }

    &__image {
        // width: 54px;
        // height: 63px;
        object-fit: contain;
        // margin-right: 37px;
        width: 3.5rem;
        height: 63px;
        height: 4.1rem;
        margin-right: 2.4rem;
        pointer-events: none;
    }
}

.no-access {
    height: 100vh;
    display: flex;
    // align-items: center;
    margin-top: 15vh;
    padding: 0 25px;
    line-height: 1.7rem;
    font-size: 1.5rem;
}

  
.loader {
    position: relative;
    width: 50px;
    height: 50px;
    border: 4px solid transparent;
    border-radius: 50%;
    border-top: 4px solid #333;
    border-bottom: 4px solid #333;
    animation: rotate 1.5s linear infinite;
}


@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
   
.pulse {
    background-color: #0072f3;
    border-radius: 50%;
    animation: pulse 0.7s ease-out;
    transform: scale(0);
    position: absolute;
}
@keyframes pulse {
    to {
    transform: scale(2);
    background-color: #fff;
    opacity: 0;
    }
}

.category__tags {
    display: inline-flex;
    white-space: nowrap;
    overflow: scroll;
    max-width: 100%;
    font-size: 0.88rem;
    margin-bottom: 1.5rem;
}

.category__tags::-webkit-scrollbar {
    display: none;
}

.tags__item:last-child {
    margin-right: 0;
}
.tags__item {
    border: 1px solid #000;
    border-radius: 1.4rem;
    padding: 1rem;
    color: #000;
    text-decoration: none;
    margin-right: 0.7rem;
    transition: .3s all;
    position: relative;
    overflow: clip;
    display: block;
}

.tags__item:hover {
    background: #0158BB;
    color: #fff;
}

.page__tag .category__title {
    font-size: 1.4rem;
    text-decoration: underline;
}

.search__container {
    display: flex;
    width: 100%;
    border-bottom: 2px solid #0158BB;
    padding-bottom: 10px;
    margin-bottom: 30px;
    justify-content: space-between;
    align-items: center;

    .search__input {
        width: 100%;
        border: 0;
        outline: none;
        display: block;
        color: #0158BB;
        font-size: 1.35rem;
        line-height: 1.62rem;
        height: 2.5rem;
        padding-left: 0.7rem;
    }

    .search__button {
        background: #0158BB;
        border-radius: 4px;
        color: #fff;
        border: 0;
        padding: 0.6rem 1.65rem;
        font-size: 1.35rem;
        line-height: 1.62rem;
        cursor: pointer;
    }

    .search__input-label img {
        width: 1.3rem;
        height: 1.3rem;
        object-fit: cover;
    }
}

.content__loader.search__loader {
    height: 30vh;
}

.search__error {
    font-size: 1.5rem;
}

.search__flush {
    background: none;
    border: 0;
    font-size: 3vw;
    width: 4rem;
    height: 2.9rem;
    cursor: pointer;
    margin-top: -0.5rem;
}


.calculator {
    &__container {
        padding: 1rem;
        background: rgba(220, 220, 229, 1);
        margin-top: 0.6rem;
    }

    &__body {
        background: #fff;
        padding: 2.1rem 1.3rem 3.2rem;
    }

    &__text {
        font-size: 1.4rem;
        color: #333333;
        line-height: 1.75rem;
        padding-bottom: 2.2rem;
    }

    &__title {
        font-weight: bold;
        font-size: 1.7rem;
        line-height: 1.9rem;
    }

    &-body {
        &__title {
            font-size: 1.8rem;
            font-weight: bold;
            line-height: 2rem;
        }

        &__subtitle {
            margin: 1.4rem 0;
            font-size: 1.2rem;
            line-height: 1.5rem;
        }

        &__input {
            input {
                width: 10.5rem;
                height: 4rem;
                border: 2px solid #000;
                border-radius: 4px;
                padding: 0 1.7rem;
                font-size: 1.4rem;
            }

            span {
                font-size: 1.4rem;
                padding-left: 1.1rem;
            }
        }

        &__buttons {
            display: flex;
            flex-direction: column;
            margin-top: 2.2rem;
            // gap: 0.9rem;
        }

        &__button {
            height: 4.2rem;
            border-radius: 4px;
            font-size: 1.8rem;
            letter-spacing: -0.03rem;
            border: 2px solid #000;
            cursor: pointer;

            &.calculator-body__button--black {
                background: #000;
                color: #fff;
                margin-bottom: 0.9rem;
            }

            &.calculator-body__button--white {
                background: #fff;
                color: #000;
            }
        }
    }
}

.category {
    &__disclaimer {
        padding: 1.6rem 2.2rem 3.3rem;

        .disclaimer__sources {
            &-title {
                font-size: 1.4rem;
                font-weight: 700;
                margin-bottom: 0.4rem;
            }
    
            &-text {
                font-size: 1.4rem;
                color: #333;
                line-height: 1.7rem;
            }

            &-top {
                font-size: 1.5rem;
                line-height: 1.7rem;
            }

            &-list {
                font-size: 1.3rem;
                padding-top: 0rem;
                padding-left: 1.9rem;
                padding-right: 0.7rem;
                line-height: 1.8rem;
                color: #333;
            }
        }
    
        .disclaimer__description {
            margin-top: 3.4rem;
    
            &-title {
                font-weight: bold;
                font-size: 1.4rem;
            }
    
            &-text {
                color: #333;
                padding-top: 0.5rem;

                a {
                    color: #333;
                }
            }
        }    

        &--soliqua {
            padding-top: 1.1rem;

            .disclaimer {
                &__sources-top {
                    padding-bottom: 1.5rem;
                }

                &__description {
                    margin-top: 1.3rem;
                }
            }
        }

        &--tj {
            padding: 3.4rem 3rem 0 0;

            .disclaimer {
                &__sources-title {
                    margin-bottom: 0.9rem;
                }
            }
        }
    }
}

.page-calc {
    position: relative;
    height: 100%;
    min-height: 100vh;

    .calcresult {
        &__container {
            position: absolute;
            background: #DCDCE5;
            width: calc(100% - 1.8rem);
            min-height: 100%;
            z-index: 1;
            padding: 1rem 0.9rem;
            opacity: 1;
            transition: opacity .5s;

            &.hide {
                opacity: 0;
            }
        }

        &__content {
            background: #fff;
            position: relative;
            min-height: calc(100vh - 2rem);
        }

        &__close {
            padding: 0;
            border: 0;
            background: none;
            position: absolute;
            right: 2.4rem;
            top: 2.4rem;        
            cursor: pointer;

            img {
                width: 1.9rem;
                height: 1.9rem;
            }
        }

        &__back {
            padding: 2.4rem 0 0 2rem;
            font-size: 1.6rem;
            font-weight: bold;

            img {
                padding: 0 0.9rem 0 0;
                width: 0.8rem;
                height: 1.2rem;
            }
        }

        &__title {
            text-align: center;
            font-weight: bold;
            font-size: 2.3rem;
            padding-top: 5rem;
        }

        &__result {
            text-align: center;
            color: #0158BB;
            font-weight: bold;
            padding-top: 2.8rem;
            font-size: 1.5rem;

            span {
                font-weight: 500;
            }
        }

        &__image {
            background-repeat: no-repeat;
            background-position: top center;
            min-height: 28.9rem;
            border-bottom: 1px solid #0158BB;
            max-width: 24.6rem;
            width: 100%;
            height: 100%;
            margin: 0 auto;
            margin-top: 3.2rem;
            padding-bottom: 2.1rem;
            background-size: 9.5rem;
        }

        &__result-data {
            font-weight: bold;
            text-align: center;
            font-size: 1.8rem;
            padding-top: 0.7rem;
            line-height: 2.4rem;
            color: #0158BB;
    
            span {
                font-weight: 500;
            }
        }
    
        &__result-info {
            margin-top: 4.7rem;
    
            .info {
                &__title {
                    text-align: center;
                    font-size: 1.3rem;
                    padding: 0 0.5rem;
                    font-weight: bold;
                }
    
                &__arrow {
                    cursor: pointer;
                    border: 0;
                    background: none;
                    margin: 0 auto;
                    display: block;
                    width: 1.3rem;
                    height: 0.8rem;
                    padding: 1.9rem;
                    background-repeat: no-repeat;
                    background-position: center;
                    outline: none;
                    background-size: 1.7rem;
                }
    
                &__content {
                    display: flex;
                    flex-direction: column;
                    gap: 0.4rem;
                    margin: 1.9rem 1.4rem 1.6rem;
                    font-size: 1.3rem;
                    overflow: hidden;
                    transition: max-height 0.5s ease-in;
                    max-height: 0;
    
                    &-row {
                        column-gap: 1rem;
                        display: grid;
                        grid-template-columns: 11.6rem 24.5rem;
                        align-content: center;
                        line-height: 1.9rem;

                        &:last-child {
                            margin-bottom: 1.1rem;
                        }
    
                        &.info__content-row--top {
                            color: #fff;
                            font-weight: bold;
                            
                            .info__content-item {
                                background: #0158BB;
                            }
                        }
                    }
    
                    &-item {
                        background: #F0F0F5;
                        padding: 1rem 0.9rem;
                        line-height: 2.1rem;
                        display: flex;
                        align-items: center;
                        min-height: 4.2rem;
                    }
    
                }
            }
        }
    }

    &__soliqua {
        .calcresult {
            &__main-image {
                margin-top: 7.6rem;
                padding: 0 1.5rem;

                img {
                    width: 100%;
                }
            }

            &__result-info {
                margin-top: 8.4rem;

                .info__arrow {
                    margin: 0 0.9rem 0 0;
                }
            }

            &__info-top {
                display: flex;
                justify-content: space-between;
                padding: 0 0.7rem;

                .info__title {
                    font-size: 1.7rem;
                    line-height: 1.8rem;
                }
            }
        }

        .soliqua-result {
            &__container {
                .top-text {
                    font-size: 1.75rem;
                    line-height: 1.9rem;
                }

                .title {
                    margin-top: 4.8rem;
                    font-weight: bold;
                    font-size: 1.7rem;
                }

                .text {
                    margin-top: 1.1rem;
                    font-size: 1.6rem;
                    line-height: 2.2rem;
                    padding-right: 3.3rem;
                }

                img {
                    width: 96.9%;
                    display: block;
                    margin: 3rem 0 4.8rem;
                }

                .soliqua-resul__sources {
                    font-size: 1.4rem;

                    &-title {
                        margin-top: 1.7rem;
                        font-size: 1.5rem;
                        font-weight: bold;

                    }

                    &-list {
                        padding-left: 1.9rem;
                        margin-top: 0rem;
                        font-size: 1.4rem;
                        line-height: 1.9rem;
                        padding-bottom: 2rem;
                    }

                    &-list-title {
                        margin-top: 1.7rem;
                        font-size: 1.5rem;
                        font-weight: bold;
                    }
                }
            }
        }
    }

    &__tj {
        .calcresult__back {
            padding-left: 0;
        }

        .calculator {
            &__body {
                padding-top: 1.2rem;
                padding-bottom: 1.3rem;
            }
        }

        .tj {
            &__top {
                height: 11.2rem;
                background-color: #86BA56;
                background-size: cover;
                background-repeat: no-repeat;
            }

            &__text {
                margin: 2.1rem 0 1.8rem;
                font-size: 1.4rem;
                line-height: 1.7rem;
            }

            &__button {
                border: 0;
                background: #86BA56;
                border-radius: 5px;
                width: 100%;
                height: 8.5rem;
                margin-bottom: 0.9rem;
                text-align: left;
                padding: 0 3.5rem 0 1.8rem;
                color: #fff;
                font-size: 1.8rem;
                background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjkiIHZpZXdCb3g9IjAgMCAyMCAyOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wLjc1MDAwMSAyNS40MjVMMTIuNzE2MiAxNC41TDAuNzUgMy41NzVMNC4zOTE4OSAwLjI1TDIwIDE0LjVMNC4zOTE4OSAyOC43NUwwLjc1MDAwMSAyNS40MjVaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K');
                background-repeat: no-repeat;
                background-position: calc(100% - 1.4rem) center;
            }
        }

        .calcresult {
            &__content {
                padding-left: 2.1rem;
                padding-right: 2.1rem;
                padding-bottom: 5.4rem;
            }

            &__title {
                text-align: left;
                line-height: 2.6rem;
                margin-bottom: 3.5rem;
            }

            &__subtitle {
                font-weight: bold;
                font-size: 1.4rem;
                margin-bottom: 0.8rem;
                line-height: 1.8rem;

                &--sec {
                    margin-top: 3.1rem;
                    margin-bottom: 1.9rem;
                }
            }

            &__text {
                font-size: 1.5rem;
                line-height: 1.8rem;
            }

            &__divider {
                height: 0.8rem;
            }

            &__buttons {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                grid-gap: 0.6rem;
                padding-right: 0.5rem;
            }

            &__button-item {
                border: 2px solid #000;
                border-radius: 4px;
                height: 5.3rem;
                font-size: 1.5rem;
                line-height: 1.6rem;
                padding: 0 1rem;
                font-weight: bold;
                transition: .3s all;

                &.active {
                    background: #86BA56;
                    color: #fff;
                    border-color: #86BA56;
                }
            }

            &__data-image {
                background-repeat: no-repeat;
                width: 100%;
                height: 13.8rem;
                background-size: contain;
                margin-top: 3.1rem;
            }

            &__data-info {
                background: #B2D393;
                margin-top: 4.5rem;
                border-radius: 4px;
                /* display: flex; */
                padding: 1.1rem 1.2rem 0;
                font-size: 1.3rem;
            }

            &__data-icon {
                width: 2.3rem;
                height: 2.3rem;
                background-size: cover;
                display: block;
            }

            &__data-text {
                margin-top: 0.8rem;
                line-height: 1.8rem;
                padding-bottom: 1.1rem;
            }
        }
    }

    &__hba {
        .calculator {
            &__body {
                padding-bottom: 1.8rem;
            }
            &-body {

                &__title {
                    font-size: 1.6rem;
                }

                &__check {
                    margin-top: 1.4rem;
                    display: flex;
                    flex-direction: column;
                    gap: 1.6rem;
                    margin-bottom: 2.4rem;

                    &-item {
                        input[type="radio"] {
                            opacity: 0;
                            width: 0;
                            height: 0;
                            position: absolute;
                        }

                        label {
                            color: #333333;
                            display: flex;
                            font-size: 1.4rem;
                            padding-top: 0.1rem;

                            sup {
                                padding-left: 0.2rem;
                                font-size: 100%;
                                line-height: 0;
                                padding-top: 0.9rem;
                            }
                        }

                        label:before {
                            content: '';
                            width: 1.3rem;
                            height: 1.3rem;
                            border: 2px solid #fff;
                            display: block;
                            border-radius: 100%;
                            outline: solid 1.9px #000;
                            margin-right: 0.7rem;
                            margin-top: -3px;
                        }

                        input[type="radio"].error + label:before,
                        input[type="radio"].error + label {
                            color: #B64051;
                            outline-color: #B64051;
                        }

                        input[type="radio"]:checked + label:before {
                            background: #000;
                        } 
                    }
                }

                &__buttons {
                    display: flex;
                    flex-direction: column;
                    margin-top: 3.9rem;
                }
            }
        }

        .category {
            &__disclaimer {
                .disclaimer {
                    &__sources {
                        padding: 0 0 0 0.6rem;

                        &-top {
                            font-size: 1.4rem;
                            margin-top: 1.4rem;
                            line-height: 1.9rem;
                            color: #333;
                            display: flex;
                            flex-direction: column;
                            gap: 1.1rem;
                            letter-spacing: -0.2px;
                            margin-bottom: 0.7rem;
                        

                            .bold {
                                font-weight: bold;
                                padding-right: 0.3rem;
                            }
                        }

                        &-list {
                            padding-top: 0.9rem
                        }
                    }

                    &__description {
                        padding: 0 0 0 0.6rem;
                    }
                }
            }
        }

        .calcresult {
            &__subtitle {
                padding: 4.4rem 2rem 0;
                text-align: center;
                font-size: 1.6rem;
                line-height: 1.8rem;
            }

            &__data {
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: 21rem;
                color: #0158BB;
                font-weight: bold;
                font-size: 5rem;

                &-text {
                    text-align: center;
                    font-size: 2.65rem;
                    max-width: 22rem;
                    line-height: 2.9rem;
                }
            }
        }
    }

    &__skf {
        .calculator {
            &__title {
                padding-bottom: 2rem;
            }

            &__bottom-text {
                color: #333;
                margin-top: 2.4rem;
                font-size: 1.4rem;
            }

            &-body__buttons {
                margin-top: 1.4rem;
            }

            &__radios {
                &-title {
                    font-size: 1.4rem;
                    padding-bottom: 1.5rem;
                }

                &-checks {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    margin-bottom: 2.6rem;
                    color: #333;
                    

                    &-item {
                        font-size: 1.3rem;
                        line-height: 1.5rem;

                        input {
                            visibility: hidden;
                            width: 0;
                            height: 0;
                            position: absolute;
                        }

                        label:before {
                            content: "";
                            position: relative;
                            width: 1.3rem;
                            height: 1.3rem;
                            border-radius: 100%;
                            outline: solid 2px #000;
                            display: block;
                            float: left;
                            margin-right: 0.9rem;
                            border: 2px solid #FFF;
                        }

                        input[type="radio"].error + label:before {
                            outline-color: red;
                        }

                        input[type="radio"].error + label {
                            color: red;
                        }

                        input[type="radio"]:checked + label:before {
                            background: #000;
                        }
                    }
                }   
            }

            &__input {
                &-container {
                    margin-top: -0.4rem;
                    display: grid;
                    grid-template-columns: 36.8% auto;

                    .input {
                        &-content {
                            &__title {
                                font-size: 1.4rem;
                                margin-bottom: 1.3rem;
                            }

                            &__input {
                                width: 5.8rem;
                                border: 2px solid #000;
                                border-radius: 3px;
                                height: 4rem;
                                padding: 0 1.6rem;
                                font-size: 1.4rem;
                                margin-right: 0.7rem;

                                &.error {
                                    border-color: red;
                                }
                            }

                            &__input-mkml {
                                width: 10.9rem;
                            }

                            &__bottom {
                                display: flex;
                                align-items: center;
                                font-size: 1.4rem;
                            }
                        }
                    }
                }
            }
        }
        
        .disclaimer {
            &__sources:first-child {
                padding-left: 0.6rem;
            }

            &__sources {
                &-abbr {
                    margin-bottom: 5.2rem;
                }

                &-title {
                    margin-bottom: 1.2rem;
                }
            }

            &__abbr {

                &-title {
                    font-size: 1.41rem;
                    font-weight: bold;
                    margin-top: 0.7rem;
                    margin-bottom: 1.8rem;
                }

                &-item {
                    font-size: 1.38rem;
                    margin-bottom: 0.25rem;
                    line-height: 1.9rem;
                }
            }
        }

        .calcresult {
            &__diag,
            &__classf,
            &__level,
            &__combi {
                transition: max-height 0.5s ease-in-out;
                max-height: 0;
                overflow: hidden;
            }

            &__content {
                padding-bottom: 1rem;
            }

            &__subtitle {
                margin-top: 3.9rem;
                text-align: center;
                font-size: 1.2rem;
                text-transform: uppercase;
                color: #333;
            }

            &__num {
                margin-top: 1.8rem;
                text-align: center;
                color: #0158BB;
                font-weight: bold;
                font-size: 4rem;

                &-sub {
                    text-align: center;
                    font-size: 1.8rem;
                    margin-top: 0.7rem;
                }
            }

            &__stage-title {
                text-align: center;
                margin-top: 3.3rem;
                font-size: 1.3rem;
                text-transform: uppercase;
                color: #333;
            }

            &__stage {
                margin-top: 0.5rem;
                text-align: center;
                color: #0158BB;
                font-weight: bold;
                font-size: 4rem;
            }

            &__description {
                margin: 3.3rem 1.4rem 0;

                &-title {
                    font-weight: bold;
                    font-size: 1.8rem;
                }

                &-text {
                    font-size: 1.4rem;
                    color: #333;
                    margin-top: 0.7rem;
                    line-height: 1.8rem;
                }
            }

            &__button {
                
                margin-top: 3.1rem;
                border: 0;
                background: no-repeat;
                font-size: 1.6rem;
                font-weight: bold;
                padding: 1rem 7rem 1rem 1.3rem;
                width: 100%;
                text-align: left;
                position: relative;
                // background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAB7SURBVHgBrdPRDYAgDATQG4UR3Ag3cARGcARHcQRHcARGUDFtoqQ0FLjkvgjv4whAXSbqkCQoUrtR//TK6tEYCWtGFwXjhlosZBd5O97ShJYwjgnNsRPyqzo6U9FVwBzKUdHNiGlosjA3YBIayXqTttphw77ogYFf85cb73VNfaCzlfoAAAAASUVORK5CYII=');
                // background-position: 91% 1.9rem;

                &:before {
                    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAB7SURBVHgBrdPRDYAgDATQG4UR3Ag3cARGcARHcQRHcARGUDFtoqQ0FLjkvgjv4whAXSbqkCQoUrtR//TK6tEYCWtGFwXjhlosZBd5O97ShJYwjgnNsRPyqzo6U9FVwBzKUdHNiGlosjA3YBIayXqTttphw77ogYFf85cb73VNfaCzlfoAAAAASUVORK5CYII=);
                    content: '';
                    width: 1.3rem;
                    height: 1.2rem;
                    z-index: 99;
                    position: absolute;
                    background-repeat: no-repeat;
                    right: 3rem;
                    top: 1.6rem;
                    transform: rotate(180deg);
                    transition: .3s all;
                }

                &.active:before {
                    transform: rotate(0deg);
                }

                &--classf {
                    margin-top: 2rem;
                }

                &--level {
                    margin-top: 0.9rem;
                }

                &--combi {
                    margin-top: 1.2rem;
                }
            }

            &__diag {
                margin-top: 1.6rem;
                padding: 0 1.3rem;
                font-size: 1.4rem;
                line-height: 1.8rem;
                color: #333;

                &-table-top {
                    background: #F0F0F5;
                    margin-top: 1.7rem;
                    padding: 1.7rem 1.9rem;
                    font-size: 1.4rem;
                    font-weight: bold;
                }

                &-sub {
                    padding: 1.3rem 1.9rem 2.9rem;
                    font-size: 1.4rem;
                }

                &-hilight {
                    color: #fff;
                    background: #0158BB;
                    padding: 2rem 2rem;
                }

                &-text {
                    padding: 1.4rem 2rem;

                    p {
                        padding-bottom: 0.5rem;
                    }
                }

                &-info {
                    margin-top: 0.6rem;

                    p {
                        margin-bottom: 0.7rem;
                    }
                }
            
            }

            &__classf {
                &-table {
                    margin-top: 0.7rem;

                    &-row.calcresult__classf-table--header {
                        height: 4.9rem;
                        background: #F0F0F5;

                        .calcresult__classf-table-col {
                            color: #333;
                            font-size: 1.3rem;
                            align-items: self-start;
                            padding-top: 0.8rem;
                            line-height: 1.8rem;
                        }
                    }

                    &-row {
                        display: grid;
                        grid-template-columns: 7.8rem 12.6rem 19.3rem;
                        height: 5.9rem;
                        border-bottom: 1px solid #000;  
                    }

                    &-row:last-child {
                        border: 0;
                    }
                    
                    &-col {
                        color: #0158BB;
                        align-items: center;
                        display: flex;
                        font-size: 2.4rem;
                    }
                    
                    &-col:nth-child(1) {
                        padding-left: 1.4rem;
                    }

                    &-col:nth-child(3) {
                        font-size: 1.3rem;
                        line-height: 1.7rem;
                    }
                }

                &-text {
                    color: #333;
                    padding: 0rem 1.3rem;
                    font-size: 1.4rem;
                    line-height: 1.8rem;

                    p {
                        margin-bottom: 0.6rem;
                    }
                }
            }

            &__bigimage {
                position: relative;

                &::before {
                    content: "";
                    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzUiIGhlaWdodD0iMzUiIHZpZXdCb3g9IjAgMCAzNSAzNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMzLjgxNDkgMzAuOTgxOEwyNy43NTc4IDI0Ljk2NDFDMzIuNDg3MyAxOC45MDUgMzEuOTg5NSAxMC4yNzI4IDI2LjU1NDYgNC44MzYxMkMyMy42OTIgMS45NzI1NSAxOS44NzUyIDAuMzk1NTA4IDE1LjgwOTQgMC4zOTU1MDhDMTEuNzQzNiAwLjM5NTUwOCA3LjkyNjc4IDEuOTcyNTUgNS4wNjQxNSA0LjgzNjEyQzIuMjAxNTIgNy42OTk2OSAwLjYyNSAxMS41MTc4IDAuNjI1IDE1LjU4NDlDMC42MjUgMTkuNjUyIDIuMjAxNTIgMjMuNDcwMSA1LjA2NDE1IDI2LjMzMzdDNy45MjY3OCAyOS4xOTcyIDExLjc0MzYgMzAuNzc0MyAxNS44MDk0IDMwLjc3NDNDMTkuMTY5OSAzMC43NzQzIDIyLjQ4ODkgMjkuNjUzNyAyNS4xNDQxIDI3LjUzNzJMMzEuMTU5NyAzMy42Mzc4QzMxLjUzMzEgMzQuMDExNCAzMS45ODk1IDM0LjE3NzQgMzIuNDg3MyAzNC4xNzc0QzMyLjk4NTIgMzQuMTc3NCAzMy40NDE1IDMzLjk2OTkgMzMuODE0OSAzMy42Mzc4QzM0LjU2MTcgMzIuOTMyMyAzNC41NjE3IDMxLjcyODggMzMuODE0OSAzMC45ODE4Wk0yNy4yNTk5IDE1LjU4NDlDMjcuMjU5OSAxOC42NTYgMjYuMDU2OCAyMS41MTk1IDIzLjg5OTQgMjMuNjc3NkMyMS43NDIxIDI1LjgzNTcgMTguODM4IDI3LjAzOTIgMTUuODA5NCAyNy4wMzkyQzEyLjc4MDggMjcuMDM5MiA5Ljg3NjY5IDI1LjgzNTcgNy43MTkzNSAyMy42Nzc2QzUuNTYyIDIxLjUxOTUgNC4zNTg4NyAxOC42MTQ1IDQuMzU4ODcgMTUuNTg0OUM0LjM1ODg3IDEyLjUxMzggNS41NjIgOS42NTAyNCA3LjcxOTM1IDcuNDkyMTlDOS44NzY2OSA1LjMzNDEzIDEyLjc4MDggNC4xMzA2IDE1LjgwOTQgNC4xMzA2QzE4Ljg3OTUgNC4xMzA2IDIxLjc0MjEgNS4zMzQxMyAyMy44OTk0IDcuNDkyMTlDMjYuMDU2OCA5LjY1MDI0IDI3LjI1OTkgMTIuNTEzOCAyNy4yNTk5IDE1LjU4NDlaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K');
                    background-repeat: no-repeat;
                    background-position: 50% 50%;
                    position: absolute;
                    width: 4.5rem;
                    height: 4.5rem;
                    background-color: #0158BB;
                    z-index: 9;
                    border-radius: 100%;
                    left: calc(50% - 2.25rem);
                    top: calc(50% - 2.25rem);
                }

                &-img {
                    cursor: pointer;
                    width: calc(100% - 2.8rem);
                    margin: 1.3rem 1.4rem;
                    position: relative;
                }
            }

            &__combi {
                &-text {
                    color: #333;
                    padding: 0.6rem 1.3rem;
                    font-size: 1.3rem;
                    line-height: 1.7rem;

                    ul {
                        padding-left: 2rem;
                        margin-top: 0.6rem;
                        padding-right: 3rem;
                    }

                    li {
                        margin-bottom: 0.7rem;
                    }
                }
            }

            &__abbr {
                margin-top: 4.9rem;
                padding: 0 1.3rem;

                &-title {
                    font-weight: bold;
                    font-size: 1.4rem;
                    margin-bottom: 1.8rem;
                }

                p {
                    margin-bottom: 0.55rem;
                    font-size: 1.3rem;
                    line-height: 1.6rem;
                    color: #333;
                }
            }
        }
    }
}

.calculator__container {
    &--soliqua {
        .calculator {
            &__img {
                padding-bottom: 1.8rem;
                width: 100%;
            }

            &__body {
                background: #fff;
                padding: 1.3rem 0.8rem 3.2rem 1.2rem;
            }

            &__text {
                padding-bottom: 3.7rem;

                &--bottom {
                    padding: 0.9rem 0 0rem;
                }
            }
        }
    }
}

.basal-calc {
    // font-family: 'Montserrat';
    margin-top: 5.2rem;
    padding: 0 2.3rem;

    &__top {
        min-height: calc(100vh - 20rem);
        margin-bottom: 3rem;

        .basal-calc__top--error {
            color: #B64051;
        }
    }

    &__title {
        font-size: 2.3rem;
        line-height: 2.6rem;
        font-weight: bold;
        color: #000;
    }

    &__subtitle {
        margin-top: 2.1rem;
        font-size: 1.6rem;
    }

    &__check-list {
        margin-top: 3.2rem;
        display: flex;
        flex-direction: column;
        gap: 1.6rem;
    }

    &__check-item {
        font-size: 1.7rem;
        line-height: 1.7rem;

        input[type="radio"] {
            width: 0;
            height: 0;
            opacity: 0;
        }

        label {
            position: relative;
            display: flex;

            &:before {
                display: block;
                content: "";
                width: 1.7rem;
                height: 1.7rem;
                border: 0.26rem solid #fff;
                border-radius: 100%;
                outline: #000 solid 0.16rem;
                min-width: 1.7rem;
            }

            span {
                padding-left: 1.7rem;
            }
        }

        input[type="radio"]:checked + label:before {
            background: #000;
        }
    }

    &__btn {
        background: #000;
        color: #fff;
        border-radius: 4px;
        border: 0;
        width: calc(100% + 2rem);
        height: 65px;
        margin: 0 -1rem;
        font-size: 2rem;
        line-height: 100%;
        margin-bottom: 1.1rem;
        font-weight: bold;
    }

    &__input {

        &-container {
            margin-top: 5rem;
            font-size: 1.5rem;
        }

        &-title {
            font-weight: bold;
        }

        &-content {
            display: flex;
            margin-top: 1.1rem;
            align-items: center;

            input {
                width: 16rem;
                height: 3.9rem;
                border-radius: 4px;
                border: 2px solid;
                font-size: 2rem;
                padding-left: 1rem;

                &.basal-calc__input--error {
                    border-color: red !important;
                }
            }

            label {
                margin-left: 0.7rem;
                font-weight: bold;
            }
        }

        
    }
}

